import React from 'react'
import { Stack } from '@mui/material'
import { RegistrationBox } from './fragments/registrationBox'
import { useAppDispatch, useAppSelector } from '../../redux/app/create_njangee_store/hooks'
import { setChooseRegistration, createChooseRegistrationSelector } from './slices/chooseRegistrationSlice'
// import PhoneIcon from '../../images/icon_images/icon-phone-square-rnd.svg'
import EnvelopIcon from '../../images/icon_images/icon-envelop-square-rnd.svg'
import ChevronGrayIcon from '../../images/icon_images/icon-chevron-right-gray.svg'
import { useTranslation } from 'react-i18next'

export const ChooseForgotPassword: React.FC = () => {
    const { t } = useTranslation(['dashboard'])
    const chooseRegistrationOption = useAppSelector(createChooseRegistrationSelector)
    const dispatch = useAppDispatch()

    const handleChooseForgotPasswordOption = (option: string): void => {
        dispatch(setChooseRegistration({ ...chooseRegistrationOption, choose_forgot_password_type: option }))
    }
    return (
        <RegistrationBox>
            <Stack className='w-full h-full py-7 px-5 sm:px-10'>
                <Stack className='my-auto'>
                    <h4>{t('registration.select_option')}</h4>
                    <p>{t('registration.select_option_caption')}</p>
                    <br />
                    <Stack className='gap-3 mt-2'>
                        {/* <Stack direction='row'
                            className='w-full items-center rounded-md bg-white cursor-pointer py-4 px-5 gap-4 shadow-lg'
                            onClick={() => { handleChooseForgotPasswordOption('phone_number') }}>
                            <img src={PhoneIcon} alt='' />
                            <Stack>
                                <h6>{t('registration.sms_code')}</h6>
                                <p className='text-secondary text-14'>{t('registration.reset_via_sms')}</p>
                            </Stack>
                            <img src={ChevronGrayIcon} alt='' className='ml-auto' />
                        </Stack> */}
                        <Stack direction='row'
                            className='w-full items-center rounded-md bg-white cursor-pointer py-4 px-5 gap-4 shadow-lg'
                            onClick={() => { handleChooseForgotPasswordOption('email') }}>
                            <img src={EnvelopIcon} alt='' />
                            <Stack>
                                <h6>{t('registration.email_a_code')}</h6>
                                <p className='text-secondary text-14'>{t('registration.reset_via_email')}</p>
                            </Stack>
                            <img src={ChevronGrayIcon} alt='' className='ml-auto' />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </RegistrationBox>
    )
}
